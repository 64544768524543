<template>
  <b-form class="my-3" @submit="onSubmit" @reset="onReset" v-if="show">
    <b-row v-if="busy">
      <b-col class="text-center">
        <simple-loader />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col xs="12" sm="8" md="9" lg="9" xl="9">
        <label class="sr-only">{{ $t("general.name") }}:</label>
        <b-input
          v-model="GeneralSummationName"
          :placeholder="$t('views.generalSummations.enterSummationName')"
          cols="12"
          size="sm"
        ></b-input>
      </b-col>
      <b-col>
        <b-button variant="success" type="submit" block size="sm">{{
          $t("general.save")
        }}</b-button>
      </b-col>
      <b-col>
        <b-button variant="danger" type="reset" block size="sm">{{
          $t("general.cancel")
        }}</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      GeneralSummationName: "",
      busy: false,
    };
  },
  methods: {
    ...mapActions("GeneralSummations", ["addGeneralSummation"]),
    ...mapGetters("GeneralSummations", ["generalSummations"]),
    onSubmit(oEvent) {
      oEvent.preventDefault();
      const that = this;
      that.busy = true;
      that
        .addGeneralSummation({
          api: that.$api,
          session: that.$session,
          generalSummationName: that.GeneralSummationName,
          enabled: true,
        })
        .then(() => {
          that.resetForm();
          this.$bvToast.toast(
            this.$t("views.generalSummations.summationAddedSuccessfuly"),
            {
              title: this.$t("views.generalSummations.generalSummationAdded"),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        })
        .catch(() => {
          that.resetForm();
        });
    },
    onReset(oEvent) {
      oEvent.preventDefault();
      this.resetForm();
    },
    resetForm() {
      this.busy = false;
      // this.show = false;
      this.$emit("update:show", false);
      this.GeneralSummationName = "";
    },
  },
};
</script>

<style></style>

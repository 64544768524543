<template>
  <div>
    <Alert />
    <b-card class="h-100" no-body>
      <b-card-header>
        <b-row class="mb-3">
          <b-col>
            <h3>{{ $t("views.generalSummations.title") }}</h3>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox v-model="Enable" name="check-button" switch>{{
              $t("views.generalSummations.showSummationEnable")
            }}</b-form-checkbox>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                />
                <b-button
                  v-if="keyword == ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                >
                  <b-icon-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.generalSummations.addGeneralSummation')"
                  @click="showAddGeneralSummation = !showAddGeneralSummation"
                >
                  <b-icon-plus />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.generalSummations.editGeneralSummation')"
                  :disabled="isSelected"
                  @click="EditGeneralSummation"
                >
                  <b-icon-pencil />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.generalSummations.removeGeneralSummation')"
                  :disabled="isSelected"
                  @click="removeGeneralSummation"
                >
                  <b-icon-trash />
                </b-button>
                <b-button
                  v-show="!Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="
                    $t('views.generalSummations.activateGeneralSummation')
                  "
                  :disabled="isSelected"
                  @click="sendActivateGeneralSummation"
                >
                  <b-icon-check />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-header>

      <AddGeneralSummation
        class="m-3 p-3"
        v-bind:show.sync="showAddGeneralSummation"
      />
      <b-card-body class="overflow-auto p-0">
        <b-table
          ref="generalSummations"
          :busy="busy"
          :fields="fields"
          :items="generalSummations"
          :per-page="perPage"
          :current-page="currentPage"
          :keyword="keyword"
          :selectable="selectable"
          :filter="keyword"
          :filter-ignored-fields="ignoredFields"
          small
          outlined
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template v-slot:table-busy>
            <simple-loader />
          </template>
          <template v-slot:cell(generalSummationName)="data">
            <div v-if="!data.item.Edit">
              {{ data.value }}
              <font-awesome-icon
                id="disabled-wrapper"
                icon="crown"
                class="text-warning"
                v-if="data.item.default"
              />
              <b-tooltip target="disabled-wrapper">{{
                $t("views.generalSummations.deafult")
              }}</b-tooltip>
            </div>
            <div v-else>
              <b-input
                v-model="data.item.generalSummationName"
                block
                size="sm"
                :placeholder="$t('views.generalSummations.enterSummationName')"
              />
            </div>
          </template>
          <template v-slot:cell(enabled)="data">
            <div v-if="!data.item.Edit">
              <div v-if="data.value" class="text-success">
                <strong>
                  <b-icon-check />
                </strong>
              </div>
              <div v-else class="text-danger">
                <strong>
                  <b-icon-x />
                </strong>
              </div>
            </div>
            <b-row v-else>
              <b-col>
                <b-button
                  variant="success"
                  block
                  size="sm"
                  v-on:click="update()"
                  >{{ $t("general.save") }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  variant="danger"
                  block
                  size="sm"
                  v-on:click="cancelUpdate()"
                  >{{ $t("general.cancel") }}</b-button
                >
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card-body>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        pills
        align="center"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddGeneralSummation from "@/components/AddGeneralSummation";
import Alert from "@/components/general/alert";

export default {
  components: {
    AddGeneralSummation,
    Alert,
  },
  watch: {
    Enable() {
      this.busy = true;
      this.getGeneralSummation({
        session: this.$session,
        api: this.$api,
        enabled: this.Enable,
      })
        .then(() => {
          this.busy = false;
        })
        .catch((e) => {
          console.log(e);
          this.busy = false;
        });
    },
  },
  data() {
    return {
      Enable: true,
      perPage: 10,
      currentPage: 1,
      busy: false,
      selected: [],
      currentSummation: { sumlageName: "" },
      showAddGeneralSummation: false,
      keyword: "",
      selectable: true,
    };
  },
  methods: {
    ...mapActions("GeneralSummations", [
      "getGeneralSummation",
      "updateGeneralSummationName",
      "updateGeneralSummationState",
    ]),
    onRowSelected(items) {
      this.selected = items;
      this.currentItem = this.selected[0];
    },
    EditGeneralSummation() {
      this.selected[0].Edit = true;
      this.currentItem = this.selected[0];
      this.selectable = false;
      this.$refs.generalSummations.refresh();
    },
    update() {
      const that = this;
      this.busy = true;
      this.updateGeneralSummationName({
        api: this.$api,
        idGeneralSummation: this.currentItem.idGeneralSummation,
        generalSummationName: this.currentItem.generalSummationName,
      })
        .then(() => {
          that.busy = false;
          that.toast(
            that.$t("views.generalSummations.updated"),
            that.$t("views.generalSummations.updatedSuccessfuly")
          );
          that.currentItem.Edit = false;
          that.selectable = true;
          that.$refs.generalSummations.refresh();
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
          that.currentItem.Edit = false;
          that.selectable = true;
          that.$refs.generalSummations.refresh();
        });
    },
    cancelUpdate() {
      this.currentItem.Edit = false;
      this.selectable = true;
      this.$refs.generalSummations.refresh();
    },
    removeGeneralSummation() {
      const that = this;
      this.busy = true;
      this.updateGeneralSummationState({
        api: this.$api,
        idGeneralSummation: this.currentItem.idGeneralSummation,
        status: false,
      })
        .then(() => {
          that.busy = false;
          that.toast(
            that.$t("views.generalSummations.removed"),
            that.$t("views.generalSummations.removedSuccessfuly")
          );
          that.$refs.generalSummations.refresh();
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
    sendActivateGeneralSummation() {
      const that = this;
      // this.busy = true;
      this.updateGeneralSummationState({
        api: this.$api,
        idGeneralSummation: this.currentItem.idGeneralSummation,
        status: true,
      })
        .then(() => {
          that.busy = false;
          that.$refs.generalSummations.refresh();
          that.toast(
            that.$t("views.generalSummations.activated"),
            that.$t("views.generalSummations.activatedSuccessfuly")
          );
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
  },
  mounted() {
    this.busy = true;
    this.getGeneralSummation({
      session: this.$session,
      api: this.$api,
      enabled: this.Enable,
    })
      .then(() => {
        this.busy = false;
      })
      .catch((e) => {
        console.log(e);
        this.busy = false;
      });
  },
  computed: {
    ...mapGetters("GeneralSummations", ["generalSummations"]),
    fields() {
      return [
        {
          key: "idGeneralSummation",
          sortable: true,
          label: this.$t("views.generalSummations.fields.id"),
        },
        {
          key: "generalSummationName",
          sortable: true,
          label: this.$t("views.generalSummations.fields.name"),
        },
        {
          key: "enabled",
          sortable: false,
          label: this.$t("views.generalSummations.fields.enabled"),
        },
      ];
    },
    ignoredFields() {
      return ["enabled"];
    },
    rows() {
      return this.generalSummations.length;
    },
    isSelected() {
      return this.selected.length < 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
